<template>
  <v-container fluid>
    <add-video-view :add-text="`+ ${$t('new event')}`" @onPressAdd="navigateAdd" />
    <v-row class="px-5 flex-column">
      <v-select
        class="place-select ml-4"
        :items="placeTypes"
        v-model="selectedPlaceType"
        item-text="name"
        item-value="value"
        dense
        outlined
        single-line
        return-object
      />
    </v-row>
    <v-row class="px-5 flex-column">
      <span class="text-subtitle-2 font-weight-bold "> {{$t('calendar events')}}</span>
      <v-row class="d-flex flex-wrap py-4 " no-gutters>
        <span v-if="!events.length" class="text-subtitle-2">
        {{$t('there are no events')}}</span>
        <v-col v-else xl="10" lg="10" md="12" sm="12" xs="12" cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <td class="text-center">
                    <span class="text-subtitle-2 font-weight-bold">
                      {{$t('event name')}}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-subtitle-2 font-weight-bold"> 
                     {{$t('event date')}}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-subtitle-2 font-weight-bold"> {{$t('hour')}} </span>
                  </td>
                  <td class="text-center">
                    <span class="text-subtitle-2 font-weight-bold"> {{$t('timezone')}} </span>
                  </td>
                  <td class="text-center">
                    <span class="text-subtitle-2 font-weight-bold">
                    {{$t('location')}}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-subtitle-2 font-weight-bold">
                    {{$t('operations')}}
                    </span>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr :class="event.disabled && 'disabled-row'" v-for="event in events" :key="event.id">
                  <td>
                    <div class="d-flex justify-center">
                      <span >
                        {{ event.name }}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      <span >
                        {{ getDate(event) }}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      <span >
                        {{ getTime(event) }}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      <span >
                        {{ getTimezone(event.tz) }}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      <span >
                        {{event.address}}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      <v-btn text @click="onEdit(event)">
                        <span class="text-subtitle-2 font-weight-bold">
                          <u>עריכה</u>
                        </span>
                      </v-btn>
                      <v-btn text @click="onShowDialog(event)">
                        <span class="text-subtitle-2 font-weight-bold">
                          <u>מחיקה</u>
                        </span>
                      </v-btn>
                      <v-btn text @click="onDeactive(event)">
                         <span class="text-subtitle-2 font-weight-bold">
                           <u v-if="!event.disabled">הפסק</u>
                           <u v-if="event.disabled">הפעל</u>
                         </span>
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-row>
    <div class="text-center">
      <v-pagination
        color="red"
        v-model="page"
        total-visible="6"
        @input="getData"
        :length="Math.ceil(total / limit)"
      ></v-pagination>
    </div>
    <v-dialog
            v-model="showDialog"
            max-width="290"
            >
            <v-card>
                <v-card-title class="text-h5">
                מחיקה
                </v-card-title>
                <v-card-text>
                האם אתה בטוח?
                </v-card-text>
                <v-card-actions>
                <v-spacer/>

                <v-btn
                    color="green darken-1"
                    text
                    @click="this.onCancelDelete"
                >
                    חזור
                </v-btn>

                <v-btn
                    color="green darken-1"
                    text
                    @click="this.onConfirmDelete"
                >
                    מחק
                </v-btn>
                </v-card-actions>
            </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import api from "@/api/api";
import moment from 'moment-timezone';
import timezones from "../../config/timezone";

export default {
  methods: {
    navigateAdd() {
      this.$router.push({ path: `/channels/${this.channelId}/event/add` });
    },
    initLoad() {
      this.$store.dispatch("SET_ACTIVE_CHANNEL_ID", this.channelId);
      this.getData();
    },
    getData() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      const params = {
        show_disabled: true,
        channel_id: this.channelId,
        place_type: this.selectedPlaceType.value,
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        group: false
      };
      api
        .getPlaces(params)
        .then((res) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          if (res.data && res.data.places.length > 0) {
            if (!params.group) {
              this.events = res.data.places.map((place) => ({
                ...place,
                group_type: place.id,
              }));
            } else {
              this.events = res.data.places.map((place) => {
                return ({
                  group_type: typeof place[0] === 'string' ? `g${place[0]}` : place[0],
                  ...(place[1]?.[0] || {}),
                  event_ids: place[1]?.map(({ id }) => id) || [],
                });
              });
            }
            this.total = res.data.total;
          } else {
            this.events = [];
          }
        })
        .catch((error) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          console.log(error);
        });
    },
    async onDeactive(event) {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      let body = {};
      const requestkey = this.selectedPlaceType.value;
      const status = !event.disabled;
      body[requestkey] = {
        disabled: status,
      }
      if (event.event_ids) {
        await Promise.allSettled(event.event_ids.map((id) =>
          new Promise((resolve, reject) => {
            api.editPlace(id, body, { place_type: this.selectedPlaceType.value })
              .then(() => {
                const index = this.events.findIndex(e => e.id == id);
                if (index > -1) {
                  const editedEvent = {
                    ...this.events[index],
                    disabled: status,
                  };
                  this.$set(this.events, index, editedEvent);
                }
                resolve();
              })
              .catch((err) => {
                console.log(err);
                reject(err);
              });
          })
        ));
      } else {
        api.editPlace(event.group_type, body, { place_type: this.selectedPlaceType.value })
            .then(() => {
              const index = this.events.findIndex(e => e.id == event.group_type);
              if (index > -1) {
                const editedEvent = {
                  ...this.events[index],
                  disabled: status,
                };
                this.$set(this.events, index, editedEvent);
              }
            })
            .catch((err) => {
              console.log(err);
            });
      }

      this.$store.dispatch("SET_TOOLBARLOAD", false);
      // api.editPlace(event.id, body, { place_type: this.selectedPlaceType.value }).then(() => {
      //   this.$store.dispatch("SET_TOOLBARLOAD", false);
      //   const index = this.events.findIndex(e => e.id == event.id);
      //   const editedEvent = {
      //     ...this.events[index],
      //     disabled: status,
      //   };
      //   this.$set(this.events, index, editedEvent);
      // })
    },
    onEdit(event) {
        this.$router.push({ path: `/channels/${this.channelId}/events/${event.group_type}/edit` });
    },
    onShowDialog(event) {
      this.showDialog = true;
      this.selectedEvent = event;
    },
    onCancelDelete() {
      this.showDialog = false;
      this.selectedEvent = null;
    },
    async onConfirmDelete() {
      this.showDialog = false;
      if (this.selectedEvent) {
        if (this.selectedEvent.event_ids) {
          await Promise.allSettled(this.selectedEvent.event_ids.map((id) =>
            new Promise((resolve, reject) => {
              api
                .deletePlace(id, { place_type: this.selectedPlaceType.value })
                .then(() => {
                  resolve();
                })
                .catch((error) => {
                  console.log(error);
                  reject(error);
                });
            })
          ));
          this.selectedEvent = null;
          this.initLoad();
        } else {
          api.deletePlace(this.selectedEvent.group_type, { place_type: this.selectedPlaceType.value })
            .then(() => {
              this.selectedEvent = null;
              this.initLoad();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    getDate(event) {
      const tz = event.tz || timezones[0].zone;
      return moment(event.taking_place).tz(tz).format("DD/MM/YYYY")
    },
    getTime(event) {
      const tz = event.tz || timezones[0].zone;
      return moment(event.taking_place).tz(tz).format("HH:mm")
    },
    getTimezone(tz) {
      const timezone = timezones.find(({ zone }) => zone === tz);
      return timezone ? timezone.name : '';
    }
  },
  data() {
    return {
      channelId: this.$route.params.channel_id,
      events: [],
      showDialog: false,
      selectedEvent: null,
      limit: 10,
      offset: 0,
      page: 1,
      total: 0,
      placeTypes: [
        {name: this.$t('lesson'), value: "event"},
        {name: this.$t('personal study'), value: "hevruta_place"}
      ],
      selectedPlaceType: {name: this.$t('lesson'), value: "event"},
    };
  },
  mounted() {
    this.initLoad();
  },
  watch: {
    $route(to) {
      if (to.params.channel_id) {
        this.channelId = to.params.channel_id;
        this.initLoad();
      }
    },
    selectedPlaceType(val) {
      if (val) {
        this.page = 1;
        this.total = 0;
        this.initLoad();
      }
    },
  },
};
</script>
<style scoped>
.disabled-row {
  background-color: #fee6e6;
}
.place-select {
  border-radius: 0px;
  min-width: 150px;
  max-width: 200px;
}
</style>
