<template>
  <v-container fluid>
    <v-row class="d-flex justify-end pa-5">
      <v-btn
        color="#ff0000"
        class="white--text"
        tile
        width="120"
        @click="navigateAdd"
      >
         +{{currentRouteName === 'Events' ? $t('new event') : $t('new place')}} 
      </v-btn>
    </v-row>
    <v-row class="d-flex py-2 px-4">
      <v-select
        class="place-select mx-2"
        :items="placeTypes"
        v-model="selectedPlaceType"
        item-text="title"
        item-value="type"
        dense
        outlined
        single-line
        return-object
      />
      <v-text-field
        dense
        class="place-search"
        :label="$t('type to search')"
        single-line
        outlined
        v-model="searchPlace"
        :loading="loading"
      />
    </v-row>
    <v-row class="pa-4 flex-wrap" no-gutters>
      <span v-if="!places.length" class="text-subtitle-2">{{$t('no places exist')}}</span>
      <v-col v-else xl="12" lg="12" md="12" sm="12" xs="12" cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <td >
                  <span class="text-subtitle-2 font-weight-bold"></span>
                </td>
                <td >
                  <span class="text-subtitle-2 font-weight-bold">{{$t('name')}}</span>
                </td>
                <td>
                  <span class="text-subtitle-2 font-weight-bold">{{$t('address')}}</span>
                </td>
                <td >
                  <span class="text-subtitle-2 font-weight-bold">{{$t('event date')}} </span
                  >
                </td>
                <td >
                  <span class="text-subtitle-2 font-weight-bold">{{$t('hour')}} </span>
                </td>
                <td class="text-center">
                  <span class="text-subtitle-2 font-weight-bold"> {{$t('timezone')}} </span>
                </td>
                <td >
                  <span class="text-subtitle-2 font-weight-bold"> {{$t('activity time')}} </span>
                </td>
                <td >
                  <span class="text-subtitle-2 font-weight-bold">{{$t('audience')}}</span>
                </td>
                <td >
                  <span class="text-subtitle-2 font-weight-bold">{{$t('more info')}}</span
                  >
                </td>
                <td >
                  <span class="text-subtitle-2 font-weight-bold">
                    {{$t('phone')}}
                  </span>
                </td>
                <td >
                  <span class="text-subtitle-2 font-weight-bold">{{$t('operations')}} </span>
                </td>
              </tr>
            </thead>

            <tbody>
              <tr :key="place.id" v-for="place in places">
                <td>
                  <div class="d-flex justify-right">
                    <v-img :src="place.image" height="50" width="50" contain />
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ place.name }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ place.address }}
                  </div>
                </td>
                <td>
                  <div v-if="place.taking_place" class="d-flex justify-right">
                    {{ getDate(place) }}
                  </div>
                </td>
                <td>
                  <div v-if="place.taking_place" class="d-flex justify-right">
                    {{ getTime(place) }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    <span >
                      {{ getTimezone(place.tz) }}
                    </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ place.opening_info }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ getAudienceName(place.audience) }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ place.more_info }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ place.collector_phone }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    <v-btn text @click="onEdit(place)">
                      <span class="text-subtitle-2 font-weight-bold">
                        <u>עריכה</u>
                      </span>
                    </v-btn>
                    <v-btn text @click="onShowDialog(place)">
                      <span class="text-subtitle-2 font-weight-bold">
                        <u>מחיקה</u>
                      </span>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-pagination
        color="red"
        v-model="page"
        total-visible="6"
        @input="getData"
        :length="Math.ceil(total / limit)"
      ></v-pagination>
    </div>
    <v-dialog v-model="showDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{$t('delete')}}
        </v-card-title>
        <v-card-text>
          {{$t('are you sure')}}
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn color="green darken-1" text @click="this.onCancelDelete">
            {{$t('cancel')}}
          </v-btn>

          <v-btn color="green darken-1" text @click="this.onConfirmDelete">
            {{$t('delete')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from "@/api/api";
import moment from 'moment-timezone';
import timezones from "../../config/timezone";

export default {
  data() {
    return {
      selectedPlaceType: null,
      placeTypes: [],
      places: [],
      limit: 10,
      total: 0,
      page: 1,
      showDialog: false,
      selectedPlace: null,
      audiences: [
        { name: this.$t('everyone'), value: "everyone" },
        { name: this.$t('male'), value: "male" },
        { name: this.$t('female'), value: "female" },
        { name: this.$t('boys'), value: "boys" },
        { name: this.$t('girls'), value: "girls" },
      ],
      searchPlace: null,
      debounce: null,
      loading: false,
    };
  },
  watch: {
    currentRouteName() {
      this.initLoad();
    },
    selectedPlaceType(val) {
      if (val) {
        this.page = 1;
        this.total = 0;
        this.searchPlace = "";
        this.getData();
      }
    },
    searchPlace(val) {
      if (this.debounce) {
        clearTimeout(this.debounce);
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.page = 1;
        this.total = 0;
        if (!val) {
          this.getData();
        } else {
          this.getSearchData(val);
        }
      }, 1000);
    }
  },
  computed: {
    currentRouteName() {
        return this.$route.name;
    }
  },
  methods: {
    navigateAdd() {
      if (this.currentRouteName === 'Events') {
        this.$router.push({ path: `/events/add` });
      } else {
        this.$router.push({ path: `/places/add` });
      }
      
    },
    initLoad() {
      this.getPlaceTypes();
    },
    getPlaceTypes() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api
        .getPlaceTypes()
        .then((res) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          if (res.data && res.data.length > 0) {
            if (this.currentRouteName === 'Events') {
              this.placeTypes = res.data.filter((e) => (e.type === 'event' || e.type === 'hevruta_place')).map((e) => {
                if (e.type === 'event') {
                  e.title = "שיעורי תורה" 
                } else {
                  e.title = "לימוד אישי"
                }
                return e
              })
            } else {
              this.placeTypes = res.data;
            }
            this.selectedPlaceType = this.placeTypes[0];
          }
        })
        .catch((error) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          console.log(error);
        });
    },
    getData() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      const params = {
        place_type: this.selectedPlaceType.type,
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        group: false
      };
      api
        .getPlaces(params)
        .then((res) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          if (res.data && res.data.places.length > 0) {
            if (!params.group) {
              this.places = res.data.places.map((place) => ({
                ...place,
                group_type: place.id,
              }));
            }
            else {
              this.places = res.data.places.map((place) => {
                return ({
                  group_type: typeof place[0] === 'string' ? `g${place[0]}` : place[0],
                  ...(place[1]?.[0] || {}),
                  event_ids: place[1]?.map(({ id }) => id) || []
                });
              });
            }
            this.total = res.data.total;
          } else {
            this.places = [];
          }
        })
        .catch((error) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          console.log(error);
        });
    },
    getSearchData(val) {
      this.loading = true;
      const params = {
        term: val,
      };
      api.searchPlace(params).then((res) => {
        if (res.data && res.data.locations) {
          this.places = res.data.locations.map((place) => ({
            ...place,
            group_type: place.id,
          }));
        }
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        this.loading = false;
      })
    },
    onEdit(place) {
      if (this.currentRouteName === 'Events') {
        this.$router.push({ path: `/events/${place.group_type}/edit` });
      } else {
        this.$router.push({ path: `/places/${place.group_type}/edit` });
      }
      
    },
    onShowDialog(place) {
      this.showDialog = true;
      this.selectedPlace = place;
    },
    onCancelDelete() {
      this.showDialog = false;
      this.selectedPlace = null;
    },
    async onConfirmDelete() {
      this.showDialog = false;
      if (this.selectedPlace) {
        api.deletePlace(this.selectedPlace.id, { place_type: this.selectedPlace.place_type })
          .then(() => {
            this.selectedPlace = null;
            this.getData();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getAudienceName(value) {
      const result = this.audiences.find((audience) => audience.value === value);
      return result?.name;
    },
    getDate(event) {
      const tz = event.tz || timezones[0].zone;
      return moment(event.taking_place).tz(tz).format("DD/MM/YYYY")
    },
    getTime(event) {
      const tz = event.tz || timezones[0].zone;
      return moment(event.taking_place).tz(tz).format("HH:mm")
    },
    getTimezone(tz) {
      const timezone = timezones.find(({ zone }) => zone === tz);
      return timezone ? timezone.name : '';
    }
  },
  mounted() {
    this.initLoad();
  },
};
</script>

<style scoped>
.place-select {
  border-radius: 0px;
  min-width: 150px;
  max-width: 200px;
}
.place-search {
  border-radius: 0px;
  font-size: 12px;
  min-width: 150px;
  max-width: 200px;
}
</style>
